header {
  background: #452282;
  padding-top: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
#desktop nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  font-family: CentraleSans-Regular, serif;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
#desktop nav .logo {
  margin-right: 100px;
}
#desktop nav ul {
  display: inline;
  list-style: none;
  margin: 0;
  padding: 10px 0;
}
#desktop nav ul li {
  display: inline;
  margin: 0 10px;
}
#desktop nav ul li a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 14px;
  display: inline;
  padding-bottom: 5px;
}
#desktop nav ul li a.active {
  border-bottom: 2px solid white;
}
#desktop nav .translator {
  margin-left: 50px;
  width: 200px;
}
#desktop nav .logo img {
  width: 50%;
}
