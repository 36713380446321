#how-to .block-item:nth-child(1){
    margin-top: -16vh;
}
#patient, #doctor{
    display: none;
}
#for-who{
    display: flex;
    width: 50%;
    justify-content: center;
    margin: 50px;
}
#for-who img{
    width: 50%;
    display: block;
    margin: auto;
}
#for-who p{
    text-align: center;
    margin: 20px;
}
#doctor{
    margin: 50px 50px 100px 50px;
}
.block {
    min-height: 60vh !important;
}
.carousel{
    margin: 0 0 100px 0;
    display: flex;
    flex-wrap: wrap;
}
.carousel__slider-tray--horizontal {
    display: block !important;
}
.carousel__slide{
    padding-bottom: 50px !important;
}
.carousel__inner-slide div{
    text-align: center;
}
.carousel__back-button,
.carousel__next-button {
    border: none;
    border-radius: 40px;
    width: 170px;
    height: 70px;
    color: #fff;
    font-weight: bold;
    font-size: 30px;
}
.carousel__back-button {
    background-image: linear-gradient(to left, #5AC7D6, #2C72B9);
}
.carousel__next-button{
    background-image: linear-gradient(to right, #5AC7D6, #2C72B9);
    margin-left: auto;
}
.carousel__dot-group{
    margin-left: auto;
    font-size: 1rem;
}
.carousel__dot-group .carousel__dot{
    color: #fff;
    background-color: transparent;
    border: none;
    font-weight: bold;
    padding: 0 10px;
    font-size: 1rem;
    opacity: 0.5;
}
.carousel__dot--selected{
    font-size: 3rem !important;
    opacity: 1 !important;
}
.faq-row-wrapper{
    margin-bottom: 100px;
    width: 84%
}
.faq-title, .faq-body, .faq-row, .row-title, .faq-title h2{
    min-height: auto;
    width: 100%;
    text-align: left;
}
/*.faq-row {
    margin: 10px 0;
}*/
.row-content {
    margin: 15px;
    line-height: 1.5;
    width: 100%;
    text-align: left;
}

@media (max-width: 991px) {
    #how-to .block-item:nth-child(1){
        margin-top: 15vh;
    }
    #for-who{
        width: 85%;
    }
    #for-who img {
        width: 60%;
    }
    .carousel__back-button, .carousel__next-button {
        width: 80px;
        height: 40px;
        font-size: 1rem;
    }
    .carousel__dot--selected{
        font-size: 2rem !important;
    }
    .carousel__dot-group .carousel__dot{
        padding: 0 3px;
    }
    .carousel__slide {
        padding-bottom: 20px !important;
    }
}