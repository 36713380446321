.downloadBlock {
  max-width: 1000px;
  flex: 0 0 0;
  margin-bottom: 100px;
  font-family: MyriadPro;
}
.downloadBlock h3 {
  font-family: CentraleSans-Bold;
  font-size: 3rem;
  margin: 0;
  padding: 0;
}
.downloadBlock p {
  font-size: 1.25rem;
  margin: 0 0 30px;
  padding: 0;
  color: #cccccc;
  white-space: pre-line;
  font-family: MyriadPro;
}
.downloadBlock a {
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
  color: #cccccc;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid white;
}
.downloadBlock h5 {
  font-family: CentraleSans-Bold;
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  color: white;
}
.downloadBlock-item {
  margin-bottom: 20px;
}
