#mobile .logo img{
    display: block;
    width: 50%;
    margin: 25px auto 30px auto;
}

#mobile nav a{
    color: #fff;
    text-decoration: none;
    margin: 10px 0;
}

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 62px;
}

.bm-burger-bars {
    background: #fff;
}

.bm-burger-bars-hover {
    background: #fff;
    opacity: 1 !important;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-cross {
    background: #fff;
}

.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

.bm-menu {
    background: #31165D;
    padding: 1.5em 1.5em 0;
    font-size: 1.15em;
}

.bm-morph-shape {
    fill: #fff;
}

.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

.bm-item {
    display: inline-block;
}

.bm-overlay {
    background: #fff;
}