#tech .block-item {
    margin-top: 175px;
}
#tech div.block:nth-child(3) > div:nth-child(2) {
    margin-top: 0 !important;
}
#tech div.block:nth-child(2) {
    margin: 150px 0 100px 0;
}
#tech .block-item img{
    width:50%;
    display: block;
    margin: auto;
}