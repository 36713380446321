footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #3b2282;
  padding: 20px 30px;
  color: white;
  font-family: CentraleSans-Regular;

  margin: auto;
  width: 50%;
}
footer .distributed,
footer .produced {
  width: 50%;
}
footer .distributed a {
  text-decoration: none;
  color: white;
}
footer .distributed > div {
  display: flex;
  align-items: center;
  padding: 0;
}
footer h3 {
  font-size: 2rem;
  margin: 0 0 10px;
}
footer h3,
h5 {
  color: #988ac0;
}
footer .distributed h5,
p,
a {
  margin: 0;
  padding: 0;
  display: block;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.25;
}
footer .distributed > div > * {
  margin: 0 10px;
}
footer .produced img {
  width: 50%;
}
footer .distributed img {
  width: 16%;
}

@media (max-width: 991px) {
  footer {
    flex-direction: column;
    width: 85%;
  }
  footer h3 {
    font-size: 1.5rem;
    text-align: center;
    margin: 0 0 25px 0;
  }
  footer .distributed, footer .produced {
    width: 100%;
    margin: 25px 0;
  }
  footer .distributed img {
    width: 25%;
  }
  footer .produced img {
    display: block;
    margin: auto;
    width: 70%;
  }
  footer .distributed > div {
    justify-content: center;
  }
}
