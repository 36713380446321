@font-face {
  font-family: Neology;
  src: url("./assets/fonts/Neology.ttf");
}
@font-face {
  font-family: MyriadPro;
  src: url("./assets/fonts/MyriadPro.otf");
}
@font-face {
  font-family: Visby;
  src: url("./assets/fonts/Visby.otf");
}
@font-face {
  font-family: CentraleSans-Regular;
  src: url("./assets/fonts/CentraleSans-Regular.otf");
}
@font-face {
  font-family: CentraleSans-Bold;
  src: url("./assets/fonts/CentraleSans-Bold.otf");
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  font-size: 18px;
  font-family: CentraleSans-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #3b2282;
}
#root {
  white-space: pre-line;
}

.app,
#root {
  height: 100%;
}

.main {
  margin: 0;
  min-height: 100%;
  background: #4a2ba3;
}

section {
  width: 70%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
}
@media (max-width: 991px) {
  section {
    width: 90%;
  }
}
