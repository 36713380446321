.block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-height: 100vh;
}
.block.block--full {
  flex-direction: column;
  margin: 100px 0;
}
.block.block--full .block-item {
  flex: 0 0 0;
}
.block-item {
  flex: 0 0 50%;
  padding: 0 5%;
}
.block-item h2 {
  font-size: 2rem;
  font-family: CentraleSans-Bold, serif;
  line-height: 2.5rem;
  white-space: pre-line;
}
.block-item h3 {
  font-size: 2rem;
  font-family: CentraleSans-Bold, serif;
  line-height: 2.5rem;
  white-space: pre-line;
  color: #cccccc;
  margin-top: -0.83em;
}
.block-item p {
  font-size: 1rem;
  font-family: CentraleSans-Regular, serif;
  line-height: 1.5rem;
}
.block-item img {
  width: 100%;
  max-width: 100%;
}
.block-item .btn {
  margin: 3rem 0 0 0;
}
.block-item a {
  text-decoration: none;
}


/* SECONDARY BLOCKS */
.block-secondary {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}
.block-secondary-item {
  width: 30%;
  padding: 0 5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.block-secondary-item img {
  width: 100%;
  max-width: 100%;
}
.block.block--full .block-secondary {
  display: flex;
  margin: 3rem 0;
}

/* THIRD BLOCK*/

.block-third{
  padding: 0 5%;
}
.block-third p{
  font-size: 1rem;
  font-family: CentraleSans-Regular, serif;
  line-height: 1.5rem;
}
@media (max-width: 991px) {
  .block{
    flex-direction: column;
  }
  .block.block--full .block-secondary {
    flex-direction: column;
  }
  .block-secondary-item {
    width: 85%;
  }
  .block-item .btn {
    margin: 2rem auto;
  }
}
