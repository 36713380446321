#contacts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
#contacts > div {
  padding: 30px;
}
#contacts > div:first-of-type {
  width: 70%;
}
#contacts img {
  width: auto;
}
#contacts p,
#contacts address {
  white-space: pre-line;
}
#contacts > div > div {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#contacts address {
  margin-right: 50px;
}
#address{
  border-left: 2px solid #fff;
}
#address img {
  width: 17%;
}

fieldset {
  border: none;
  border-bottom: 2px solid white;
  overflow-y: visible;
  /*position: relative;*/
  padding: 10px;
}
fieldset input,
fieldset textarea {
  background: none;
  outline: none;
  border: none;
  /*position: relative;*/
  width: 100%;
  margin: 0;
  padding: 0 10px;
  font-size: 18px;
  color: white;
  font-family: CentraleSans-Regular;
}
fieldset textarea {
  min-height: 100px;
}
fieldset input::placeholder,
fieldset textarea::placeholder {
  transition: 0.5s ease-in-out;
  color: white;
}
fieldset input:focus::placeholder,
fieldset textarea:focus::placeholder {
  transform: translateY(100px);
  opacity: 0;
}

@media (max-width: 991px) {
  #contacts {
    flex-direction: column;
  }
  #address{
    border-left: none;
  }
  #address img {
    display: none;
  }
  #contacts > div:first-of-type {
    width: 85%;
  }
  #contacts div:nth-child(1){
    margin-top: 15vh;
  }
}
