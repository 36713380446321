.btn {
  background: none;
  border-radius: 50px;
  padding: 10px 30px;
  border: 2px solid #50cde1;
  color: white;
  margin: 20px auto;
  display: block;
  cursor: pointer;
  outline: none;
  font-family: CentraleSans-Regular;
  font-size: 20px;
  line-height: 1;
  transition: border-top 0.6s cubic-bezier(0.67, 0.83, 0.67, 0.17) 0.6s,
    border-right 0.6s cubic-bezier(0.67, 0.83, 0.67, 0.17) 0.4s,
    border-bottom 0.6s cubic-bezier(0.67, 0.83, 0.67, 0.17) 0.2s,
    border-left 0.6s cubic-bezier(0.67, 0.83, 0.67, 0.17) 0s;
}
.btn:hover {
  border: 2px solid #2183c1;
  transition: border-top 0.6s cubic-bezier(0.17, 0.67, 0.83, 0.67) 0s,
    border-right 0.6s cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.2s,
    border-bottom 0.6s cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.4s,
    border-left 0.6s cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.6s;
}
