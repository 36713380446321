.translator {
  background: none;
  display: flex;
  align-items: center;
}
.translator select {
  background: none;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 14px;
  font-family: CentraleSans-Regular;
  position: relative;
  outline: none;
}
.translator img {
  margin-right: 10px;
  width: 25px;
  height: auto;
}
